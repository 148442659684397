/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames'
import { Icon, IconColor, IconName } from 'components/DataDisplay/Icon/Icon'
import Link from 'next/link'
import React, { ComponentPropsWithRef, FC } from 'react'

import styles from './ButtonLink.module.scss'

export enum ButtonLinkDesign {
  RED,
  BLUE,
  GREY,
  BURGUNDY,
  GREEN,
  YELLOW,
  LIGHT_BLUE,
  OUTLINE_BLUE,
  OUTLINE_RED,
}

export enum ButtonLinkSize {
  BIG,
  SMALL,
  ICON,
}

type ButtonLinkProps = {
  href: string
  size?: ButtonLinkSize
  design?: ButtonLinkDesign
  fullWidth?: boolean
  autoWidth?: boolean
  iconName?: IconName
  iconColor?: IconColor
  disabled?: boolean
}

const buttonLinkDesignToStyle: { [key in ButtonLinkDesign]: string } = {
  [ButtonLinkDesign.RED]: styles.red,
  [ButtonLinkDesign.BLUE]: styles.blue,
  [ButtonLinkDesign.GREY]: styles.grey,
  [ButtonLinkDesign.BURGUNDY]: styles.burgundy,
  [ButtonLinkDesign.GREEN]: styles.green,
  [ButtonLinkDesign.YELLOW]: styles.yellow,
  [ButtonLinkDesign.LIGHT_BLUE]: styles.lightBlue,
  [ButtonLinkDesign.OUTLINE_BLUE]: styles.outlineBlue,
  [ButtonLinkDesign.OUTLINE_RED]: styles.outlineRed,
}

const buttonLinkSizeToStyle: { [key in ButtonLinkSize]: string } = {
  [ButtonLinkSize.BIG]: styles.big,
  [ButtonLinkSize.SMALL]: styles.small,
  [ButtonLinkSize.ICON]: styles.icon,
}

export const ButtonLink: FC<ComponentPropsWithRef<'a'> & ButtonLinkProps> = ({
  design = ButtonLinkDesign.BLUE,
  size = ButtonLinkSize.BIG,
  iconColor = IconColor.WHITE,
  fullWidth = false,
  iconName,
  className,
  href,
  disabled,
  onClick,
  ...props
}) => {
  const handleClick = (e: any) => {
    if (!disabled) {
      onClick?.(e)
    }
  }

  return (
    <Link href={!disabled ? href : '#'} passHref scroll={!disabled ? false : undefined}>
      <a
        className={classNames(
          className,
          styles.button,
          buttonLinkSizeToStyle[size],
          buttonLinkDesignToStyle[design],
          fullWidth && styles.fullwidth,
          disabled && styles.disabled
        )}
        aria-disabled={disabled}
        onClick={handleClick}
        {...props}
      >
        {iconName && <Icon name={iconName} color={iconColor} size={24} style={{ letterSpacing: 0 }} />}
        {size !== ButtonLinkSize.ICON && props.children}
      </a>
    </Link>
  )
}
