import classNames from 'classnames'
import { ButtonLink, ButtonLinkDesign } from 'components/ButtonLink/ButtonLink'
import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { FC } from 'react'
import { Testimonial } from './components/Testimonial/Testimonial'
import styles from './Testimonials.module.scss'

type VideoType = {
  thumbnailUrl: string
  videoUrl: string
  videoTitle: string
}

type ImageType = {
  imageUrl: string
}

export type TestimonialType = {
  name: string
  quote: string
  avatarRoute?: string
  thumb: VideoType | ImageType
  isSingular?: boolean
  customText?: string
}

type TestimonalsProps = {
  title: string
  subtitle: string
  testimonials: TestimonialType[]
  buttonText: string
  buttonLink: string
  className?: string
  testimonialClassName?: string
}

export const Testimonials: FC<TestimonalsProps> = ({
  title,
  subtitle,
  testimonials,
  buttonLink,
  buttonText,
  className,
  testimonialClassName,
}) => {
  return (
    <div className={(styles.containerFluid, className)}>
      <div className={classNames(styles.container, styles.testimonialsContainer)}>
        <Typography typographyType={TypographyType.H2_TITLE} className={styles.title}>
          {title}
        </Typography>

        <Typography typographyType={TypographyType.PARAGRAPH} className={styles.subtitle}>
          {subtitle}
        </Typography>

        <div className={styles.quotesContainer}>
          {testimonials.map((testimonial) => (
            <Testimonial
              key={testimonial.name}
              name={testimonial.name}
              quote={testimonial.quote}
              avatarRoute={testimonial.avatarRoute}
              thumb={testimonial.thumb}
              isSingular={testimonial.isSingular}
              customText={testimonial.customText}
              className={testimonialClassName}
            />
          ))}
        </div>

        {buttonText && (
          <div className={styles.testimonialButtonWrapper}>
            <ButtonLink href={buttonLink} target="_blank" design={ButtonLinkDesign.BLUE} className={styles.testimonialButton}>
              {buttonText}
            </ButtonLink>
          </div>
        )}
      </div>
    </div>
  )
}
